import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue3Storage from 'vue3-storage'
import apiMixins from './mixins/api-mixins'
import utilMixins from './mixins/util-mixins'

const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value()
            }
        }
        document.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted: (el) => {
        document.removeEventListener('click', el.clickOutsideEvent)
    },
}

createApp(App)
    .use(store)
    .use(router)
    .use(Vue3Storage, { namespace: 'ht_session_', storage: 'session' })
    .mixin(apiMixins)
    .mixin(utilMixins)
    .directive('click-outside', clickOutside)
    .mount('#app')
