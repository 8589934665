const state = {
    token: null,
    isRoot: false,
    isSuper: false,
    companyId: -99,
    userInfo: null,
    isLoading: false,
    notificationId: null,
    searhFilter: {
        monitoringVin: null,
    },
}

export default state
