<template>
    <router-view />
</template>
<script>
export default {
    name: 'App',
    created() {
        const tokenObj = this.$storage.getStorageSync('tokenObj')

        if (tokenObj) {
            // 저장된 토큰이 있다면 vuex store에 저장
            console.log(tokenObj)
            this.$store.commit('setToken', tokenObj)
        }

        const notificationId = this.$storage.getStorageSync('notificationId')

        if (notificationId) {
            // 저장된 토큰이 있다면 vuex store에 저장
            console.log(notificationId)
            this.$store.commit('setNotificationId', notificationId)
        }

        const searchFilterObj = this.$storage.getStorageSync('searchFilterObj')

        if (searchFilterObj) {
            this.$store.commit('setSearchFilter', searchFilterObj)
        }
    },
}
</script>

<style lang="scss">
@import '@/styles/common.scss';
</style>
