const mutations = {
    logout(state) {
        state.token = null
        state.companyId = -99
        state.isRoot = false
        state.isSuper = false
        state.searchFilter = null
        state.notificationId = null
    },
    setToken(state, tokenObj) {
        state.token = tokenObj.token
        state.companyId = tokenObj.companyId
        state.isRoot = tokenObj.isRoot
        state.isSuper = tokenObj.isSuper
    },
    setSearchFilter(state, filterObj) {
        state.searchFilter = filterObj
    },
    setNotificationId(state, value) {
        state.notificationId = value
    },
    setLoading(state, value) {
        state.isLoading = value
    },
}

export default mutations
