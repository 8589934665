import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'

const LayoutContainer = () => import('@/views/common/layout/LayoutContainer')
const DashBoardPage = () => import('@/views/DashBoardPage/DashBoardPage')
const RealTimePage = () => import('@/views/Monitoring/RealTimePage/RealTimePage')
const TEMPSAlertPage = () => import('@/views/Monitoring/TEMPSAlertPage/TEMPSAlertPage')
const TEMPSAlertDetailPage = () => import('@/views/Monitoring/TEMPSAlertPage/TEMPSAlertDetailPage')
const DrivingLogPage = () => import('@/views/Monitoring/DrivingLogPage/DrivingLogPage')
const DrivingLogDetailPage = () => import('@/views/Monitoring/DrivingLogPage/DrivingLogDetailPage')
const Login = () => import('@/views/LoginPage/LoginPage')
const ResetPasswordPage = () => import('@/views/LoginPage/ResetPasswordPage')
const UnlockAccountPage = () => import('@/views/LoginPage/UnlockAccountPage')
const UnlockCompletedPage = () => import('@/views/LoginPage/UnlockCompletedPage')
const ChangePasswordPage = () => import('@/views/LoginPage/ChangePasswordPage')
const SignUpPage = () => import('@/views/LoginPage/SignUpPage')
const ServicePage = () => import('@/views/Service/ServicePage/ServicePage')
const ServiceDetailPage = () => import('@/views/Service/ServicePage/ServiceDetailPage')
const TrailerPage = () => import('@/views/Product/TrailerPage/TrailerPage')
const TrailerDetailPage = () => import('@/views/Product/TrailerPage/TrailerDetailPage')
const UserPage = () => import('@/views/User/UserPage/UserPage')
const UserDetailPage = () => import('@/views/User/UserPage/UserDetailPage')
const CompanyPage = () => import('@/views/User/CompanyPage/CompanyPage')
const CompanyDetailPage = () => import('@/views/User/CompanyPage/CompanyDetailPage')
const ProfilePage = () => import('@/views/OtherPage/ProfilePage')
const ErrorPage = () => import('@/views/OtherPage/ErrorPage')

const routes = [
    {
        path: '/Main',
        component: LayoutContainer,
        children: [
            {
                path: '/DashBoardPage',
                name: 'DashBoardPage',
                component: DashBoardPage,
                meta: { unsafe: false, top: -1, left: -1 },
            },
            {
                path: '/RealTimePage',
                name: 'RealTimePage',
                component: RealTimePage,
                meta: { unsafe: false, top: 1, left: 0 },
            },
            {
                path: '/TEMPSAlertPage',
                name: 'TEMPSAlertPage',
                component: TEMPSAlertPage,
                meta: { unsafe: false, top: 1, left: 1 },
            },
            {
                path: '/TEMPSAlertDetailPage/:id',
                name: 'TEMPSAlertDetailPage',
                component: TEMPSAlertDetailPage,
                meta: { unsafe: false, top: 1, left: 1 },
            },
            {
                path: '/DrivingLogPage',
                name: 'DrivingLogPage',
                component: DrivingLogPage,
                meta: { unsafe: false, top: 1, left: 2 },
            },
            {
                path: '/DrivingLogDetailPage/:vin/:id',
                name: 'DrivingLogDetailPage',
                component: DrivingLogDetailPage,
                meta: { unsafe: false, top: 1, left: 2 },
            },
            {
                path: '/TrailerPage',
                name: 'TrailerPage',
                component: TrailerPage,
                meta: { unsafe: false, top: 2, left: 0 },
            },
            {
                path: '/TrailerDetailPage/:id',
                name: 'TrailerDetailPage',
                component: TrailerDetailPage,
                meta: { unsafe: false, top: 2, left: 0 },
            },
            {
                path: '/ServicePage',
                name: 'ServicePage',
                component: ServicePage,
                meta: { unsafe: false, top: 3, left: 0 },
            },
            {
                path: '/ServiceDetailPage/:id',
                name: 'ServiceDetailPage',
                component: ServiceDetailPage,
                meta: { unsafe: false, top: 3, left: 0 },
            },
            {
                path: '/UserPage',
                name: 'UserPage',
                component: UserPage,
                meta: { unsafe: false, top: 4, left: 0 },
            },
            {
                path: '/UserDetailPage/:id',
                name: 'UserDetailPage',
                component: UserDetailPage,
                meta: { unsafe: false, top: 4, left: 0 },
            },
            {
                path: '/CompanyPage',
                name: 'CompanyPage',
                component: CompanyPage,
                meta: { unsafe: false, top: 4, left: 1 },
            },
            {
                path: '/CompanyDetailPage/:id',
                name: 'CompanyDetailPage',
                component: CompanyDetailPage,
                meta: { unsafe: false, top: 4, left: 1 },
            },
            {
                path: '/ProfilePage',
                name: 'ProfilePage',
                component: ProfilePage,
                meta: { unsafe: false, top: 99, left: 0 },
            },
        ],
    },
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: { unsafe: true },
    },
    {
        path: '/ResetPasswordPage',
        name: 'ResetPasswordPage',
        component: ResetPasswordPage,
        meta: { unsafe: true },
    },
    {
        path: '/UnlockAccountPage',
        name: 'UnlockAccountPage',
        component: UnlockAccountPage,
        meta: { unsafe: true },
    },
    {
        path: '/UnlockCompletedPage',
        name: 'UnlockCompletedPage',
        component: UnlockCompletedPage,
        meta: { unsafe: true },
    },
    {
        path: '/ChangePasswordPage',
        name: 'ChangePasswordPage',
        component: ChangePasswordPage,
        meta: { unsafe: true },
    },
    {
        path: '/SignUpPage',
        name: 'SignUpPage',
        component: SignUpPage,
        meta: { unsafe: true },
    },
    {
        path: '/:notFound(.*)', // 소괄호 사이의 정규 표현식
        component: ErrorPage,
        meta: { unsafe: true },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
})

// 주소가 변할 때
router.beforeEach((to, from, next) => {
    // routes 에 meta.unsafe:true 가 정의되어 있지 않다면, 로그인이 필요한 페이지로 간주한다.
    if (!to.meta.unsafe && !store.getters.isLogin) {
        alert('You have to Sign In')
        return next('/') // 로그인 페이지로 강제이동
    }

    if (to.name.includes('User') || to.name.includes('Company')) {
        if (!(store.state.isRoot || store.state.isSuper)) {
            alert('This page is inaccessible.')
            router.go(-1)
            return
        }
    }

    if (to.name.includes('Profile') && store.state.isRoot) {
        alert('This page is inaccessible.')
        router.go(-1)
        return
    }

    return next()
})

export default router
