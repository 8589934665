const getters = {
    isLogin(state) {
        return state.token !== null
    },
    isLoading(state) {
        return state.isLoading
    },
}

export default getters
