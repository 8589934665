import { createStore } from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'

const store = createStore({
    state() {
        return state
    },
    getters,
    mutations,
})

export default store
