export default {
    methods: {
        $getConvertedDate(inputDate) {
            if (inputDate == null) return null
            const tempDate = new Date(inputDate)

            const year = tempDate.getFullYear()
            let month = tempDate.getMonth() + 1
            if (month < 10) {
                month = '0' + month
            }
            let date = tempDate.getDate()
            if (date < 10) {
                date = '0' + date
            }
            return month + '/' + date + '/' + year
        },
        $getConvertedDateForAPI(inputDate) {
            if (inputDate == null) return null
            const tempDate = new Date(inputDate)

            const year = tempDate.getFullYear()
            let month = tempDate.getMonth() + 1
            if (month < 10) {
                month = '0' + month
            }
            let date = tempDate.getDate()
            if (date < 10) {
                date = '0' + date
            }
            return year + '-' + month + '-' + date
        },
        $getDisplayedDate(inputDate) {
            const tempDate = inputDate.split('-')
            return tempDate[1] + '/' + tempDate[2] + '/' + tempDate[0]
        },
        $getHMTime(time) {
            if (time == null || time === 0) return '0 min'

            let hours = Math.floor(time / 60)

            if (hours > 0) hours += ' h '
            else hours = ''

            let minutes = time % 60

            if (minutes > 0) minutes += ' min'
            else minutes = ''

            return hours + minutes
        },
        $getConvertedDateTime(inputDateTime) {
            if (inputDateTime == null) return ''

            const tempDate = inputDateTime

            const year = tempDate.getFullYear()
            let month = tempDate.getMonth() + 1
            if (month < 10) {
                month = '0' + month
            }
            let date = tempDate.getDate()
            if (date < 10) {
                date = '0' + date
            }

            let hour = tempDate.getHours()
            if (hour < 10) {
                hour = '0' + hour
            }

            let min = tempDate.getMinutes()
            if (min < 10) {
                min = '0' + min
            }

            let sec = tempDate.getSeconds()
            if (sec < 10) {
                sec = '0' + sec
            }

            return month + '/' + date + '/' + year + ' ' + hour + ':' + min + ':' + sec
        },
        $getConvertedTime(inputDateTime) {
            if (inputDateTime == null) return ''

            const tempDate = new Date(inputDateTime)

            let hour = tempDate.getHours()
            if (hour < 10) {
                hour = '0' + hour
            }

            let min = tempDate.getMinutes()
            if (min < 10) {
                min = '0' + min
            }

            let sec = tempDate.getSeconds()

            if (sec < 10) {
                sec = '0' + sec
            }

            return hour + ':' + min + ':' + sec
        },
    },
}
