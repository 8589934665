import axios from 'axios'
import store from '@/store/index'
import router from '@/router/index'

function getBaseHeaders() {
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-TenantName': 'hyundai',
        Authorization: 'Bearer ' + store.state.token,
    }
}

function getBaseHeadersNoToken() {
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-TenantName': 'hyundai',
    }
}

export default {
    methods: {
        async $callAPI(url, method, params, data) {
            if (url !== '/api/notification') {
                this.$store.commit('setLoading', true)
            }
            console.log('url :: ', url)
            if (params) {
                console.log('params :: ', params)
            }
            if (data) {
                console.log('data :: ', data)
            }
            let result = {}
            result = await axios({
                method: method,
                url: url,
                params: params,
                data: { ...data },
                headers: getBaseHeaders(),
            }).catch((e) => {
                console.log(e.response)
                this.$store.commit('setLoading', false)

                if (e.response.status === 403) {
                    alert('You have to sign in again. Your token has been expired')
                    this.$store.commit('logout')
                    this.$storage.removeStorageSync('tokenObj')
                    this.$storage.removeStorageSync('searchFilterObj')
                    this.$storage.removeStorageSync('notificationId')
                    router.replace({ name: 'Login' })
                }

                return e.response
            })

            if (result.data === '') {
                return result
            }

            this.$store.commit('setLoading', false)
            return result.data
        },
        async $callAPINoToken(url, method, params, data) {
            this.$store.commit('setLoading', true)
            console.log(url, data)
            let result = {}
            result = await axios({
                method: method,
                url: url,
                params: params,
                data: { ...data },
                headers: getBaseHeadersNoToken(),
            }).catch((e) => {
                console.log(e.response)
                this.$store.commit('setLoading', false)

                return e.response
            })

            if (result.data === '') {
                return result
            }

            this.$store.commit('setLoading', false)
            return result.data
        },
        async $downloadAPI(url) {
            this.$store.commit('setLoading', true)
            axios({
                url: url, // your url
                method: 'GET',
                responseType: 'blob', // important
                headers: {
                    ...getBaseHeaders(),
                    'Content-Type': 'application/octet-stream',
                },
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url

                    let filename = ''
                    const disposition = response.headers['content-disposition']
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                        const matches = filenameRegex.exec(disposition)
                        if (matches !== null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '')
                        }
                    }
                    link.setAttribute('download', filename) // or any other extension
                    document.body.appendChild(link)
                    link.click()
                })
                .catch((e) => {
                    console.log(e.response)
                    this.$store.commit('setLoading', false)
                })
            this.$store.commit('setLoading', false)
        },
    },
}
